/**
 * IMPORTANT: We make our web using 'Desktop-First' strategy
 * that means it does not make sense to add any media query for desktop
 * keep the media query for mobile (mdDown & smDown) only. don't add/change/modify anything below
 */

/* Container query support */
.parentWidth {
    container-type: inline-size;
    container-name: parent;
}

/* Default viewport-based media queries */
@media screen and (min-width: 600px) {
    .smUpDisplayNone {
        display: none !important;
    }
}

@media screen and (min-width: 900px) {
    .mdUpDisplayNone {
        display: none !important;
    }
}

@media screen and (min-width: 1200px) {
    .lgUpDisplayNone {
        display: none !important;
    }
}

@media screen and (min-width: 1535px) {
    .xlUpDisplayNone {
        display: none !important;
    }
}

@media screen and (max-width: 1535px) {
    .xlDownDisplayNone {
        display: none !important;
    }
}

@media screen and (max-width: 1200px) {
    .lgDownDisplayNone {
        display: none !important;
    }
}

@media screen and (max-width: 1200px) {
    .lgDownPositionStatic {
        position: static !important;
    }

    .lgDownPositionStatic {
        position: static !important;
    }

    .lgDownDisplayNone {
        display: none !important;
    }

    .lgDownFlexUnset {
        flex: unset !important;
    }

    .lgDownPadding0 {
        padding: 0 !important;
    }

    .lgDownWrap {
        flex-wrap: wrap !important;
    }

    .lgDownWrapReverse {
        flex-wrap: wrap-reverse !important;
    }

    .lgDownJustifySpaceBetween {
        justify-content: space-between !important;
    }

    .lgDownAlignStart {
        align-items: flex-start !important;
    }

    .lgDownAlignCenter {
        align-items: center !important;
    }

    .lgDownJustifyCenter {
        justify-content: center !important;
    }

    .lgDownRow {
        flex-direction: row !important;
    }

    .lgDownRowReverse {
        flex-direction: row-reverse !important;
    }

    .lgDownColumn {
        flex-direction: column !important;
    }

    .lgDownColumnReverse {
        flex-direction: column-reverse !important;
    }

    .lgDownTextAlignLeft {
        text-align: left !important;
    }

    .lgDownTextAlignCenter {
        text-align: center !important;
    }

    .lgDownWidth100 {
        width: 100% !important;
    }

    .lgDownWidthAuto {
        width: auto !important;
    }

    .lgDownMaxWidthUnset {
        max-width: unset !important;
    }

    .lgDownHeight100 {
        height: 100% !important;
    }

    .lgDownMarginAuto {
        margin: auto !important;
    }

    .lgDownScrollX {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .lgDownScrollY {
        overflow-y: auto;
        overflow-x: hidden;
    }

    /* this UI: https://i.imgur.com/QetUhi8.gif */
    .lgDownFullWidthScrollableX {
        overflow-x: auto;
        width: calc(100vw);
        margin-left: -5.4rem;
        padding-left: 5.4rem;
        padding-right: 3.6rem;
    }

    .lgDownRemoveSticky {
        position: relative !important;
    }
}

/**
 * NOTE: you can add any breakpoints styles here below.
 * Mostly common responsive styles should just be applied to `mdDown` and `smDown`.
 * Only applied the very generic (that you think) could be globally used throughout the app
 */
@media screen and (max-width: 900px) {
    .mdDownPositionStatic {
        position: static !important;
    }

    .mdDownDisplayNone {
        display: none !important;
    }

    .mdDownFlexUnset {
        flex: unset !important;
    }

    .mdDownPadding0 {
        padding: 0 !important;
    }

    .mdDownWrap {
        flex-wrap: wrap !important;
    }

    .mdDownWrapReverse {
        flex-wrap: wrap-reverse !important;
    }

    .mdDownJustifySpaceBetween {
        justify-content: space-between !important;
    }

    .mdDownAlignStart {
        align-items: flex-start !important;
    }

    .mdDownAlignCenter {
        align-items: center !important;
    }

    .mdDownJustifyCenter {
        justify-content: center !important;
    }

    .mdDownRow {
        flex-direction: row !important;
    }

    .mdDownRowReverse {
        flex-direction: row-reverse !important;
    }

    .mdDownColumn {
        flex-direction: column !important;
    }

    .mdDownColumnReverse {
        flex-direction: column-reverse !important;
    }

    .mdDownTextAlignLeft {
        text-align: left !important;
    }

    .mdDownTextAlignCenter {
        text-align: center !important;
    }

    .mdDownWidth100 {
        width: 100% !important;
    }

    .mdDownWidthAuto {
        width: auto !important;
    }

    .mdDownMaxWidthUnset {
        max-width: unset !important;
    }

    .mdDownMinWidthUnset {
        min-width: unset !important;
    }

    .mdDownHeight100 {
        height: 100% !important;
    }

    .mdDownHeightAuto {
        height: auto !important;
    }

    .mdDownMarginAuto {
        margin: auto !important;
    }

    .mdDownScrollX {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .mdDownScrollY {
        overflow-y: auto;
        overflow-x: hidden;
    }

    /* this UI: https://i.imgur.com/QetUhi8.gif */
    .mdDownFullWidthScrollableX {
        overflow-x: auto;
        width: calc(100vw);
        margin-left: -5.4rem;
        padding-left: 5.4rem;
        padding-right: 3.6rem;
    }

    .mdDownRemoveSticky {
        position: relative !important;
    }
}

@media screen and (max-width: 600px) {
    .smDownPositionStatic {
        position: static !important;
    }

    .smDownDisplayNone {
        display: none !important;
    }

    .smDownFlexUnset {
        flex: unset !important;
    }

    .smDownPadding0 {
        padding: 0 !important;
    }

    .smDownWrap {
        flex-wrap: wrap !important;
    }

    .smDownWrapReverse {
        flex-wrap: wrap-reverse !important;
    }

    .smDownJustifySpaceBetween {
        justify-content: space-between !important;
    }

    .smDownJustifyCenter {
        justify-content: center !important;
    }

    .smDownAlignStart {
        align-items: flex-start !important;
    }

    .smDownAlignCenter {
        align-items: center !important;
    }

    .smDownRow {
        flex-direction: row !important;
    }

    .smDownRowReverse {
        flex-direction: row-reverse !important;
    }

    .smDownColumn {
        flex-direction: column !important;
    }

    .smDownColumnReverse {
        flex-direction: column-reverse !important;
    }

    .smDownTextAlignLeft {
        text-align: left !important;
    }

    .smDownTextAlignCenter {
        text-align: center !important;
    }

    .smDownWidth100 {
        width: 100% !important;
    }

    .smDownWidthAuto {
        width: auto !important;
    }

    .smDownMaxWidthUnset {
        max-width: unset !important;
    }

    .smDownHeight100 {
        height: 100% !important;
    }

    .smDownHeightAuto {
        height: auto !important;
    }

    .smDownMarginAuto {
        margin: auto !important;
    }

    .smDownScrollX {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .smDownScrollY {
        overflow-y: auto;
        overflow-x: hidden;
    }

    /* this UI: https://i.imgur.com/QetUhi8.gif */
    .smDownFullWidthScrollableX {
        overflow-x: auto;
        width: calc(100vw - 13rem);
        max-width: 100%;
        padding-left: 4rem;
        padding-right: 2rem;
    }
}

/**
 * Breakpoints for image light/dark
 */
[data-theme]:not([data-theme="dark"]) .imgLight {
    display: unset;
}

[data-theme]:not([data-theme="dark"]) .imgDark {
    display: none;
}

[data-theme="dark"] .imgLight {
    display: none;
}

[data-theme="dark"] .imgDark {
    display: unset;
}

/* Container-based breakpoints for .parentWidth elements */
@container parent (min-width: 600px) {
    .parentWidth .smUpDisplayNone {
        display: none !important;
    }
}

@container parent (min-width: 900px) {
    .parentWidth .mdUpDisplayNone {
        display: none !important;
    }
}

@container parent (min-width: 1200px) {
    .parentWidth .lgUpDisplayNone {
        display: none !important;
    }
}

@container parent (min-width: 1535px) {
    .parentWidth .xlUpDisplayNone {
        display: none !important;
    }
}

@container parent (max-width: 1535px) {
    .parentWidth .xlDownDisplayNone {
        display: none !important;
    }
}

@container parent (max-width: 1200px) {
    .parentWidth .lgDownPositionStatic {
        position: static !important;
    }

    .parentWidth .lgDownDisplayNone {
        display: none !important;
    }

    .parentWidth .lgDownFlexUnset {
        flex: unset !important;
    }

    .parentWidth .lgDownPadding0 {
        padding: 0 !important;
    }

    .parentWidth .lgDownWrap {
        flex-wrap: wrap !important;
    }

    .parentWidth .lgDownWrapReverse {
        flex-wrap: wrap-reverse !important;
    }

    .parentWidth .lgDownJustifySpaceBetween {
        justify-content: space-between !important;
    }

    .parentWidth .lgDownAlignStart {
        align-items: flex-start !important;
    }

    .parentWidth .lgDownAlignCenter {
        align-items: center !important;
    }

    .parentWidth .lgDownJustifyCenter {
        justify-content: center !important;
    }

    .parentWidth .lgDownRow {
        flex-direction: row !important;
    }

    .parentWidth .lgDownRowReverse {
        flex-direction: row-reverse !important;
    }

    .parentWidth .lgDownColumn {
        flex-direction: column !important;
    }

    .parentWidth .lgDownColumnReverse {
        flex-direction: column-reverse !important;
    }

    .parentWidth .lgDownTextAlignLeft {
        text-align: left !important;
    }

    .parentWidth .lgDownTextAlignCenter {
        text-align: center !important;
    }

    .parentWidth .lgDownWidth100 {
        width: 100% !important;
    }

    .parentWidth .lgDownMaxWidthUnset {
        max-width: unset !important;
    }

    .parentWidth .lgDownHeight100 {
        height: 100% !important;
    }

    .parentWidth .lgDownMarginAuto {
        margin: auto !important;
    }

    .parentWidth .lgDownScrollX {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .parentWidth .lgDownScrollY {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .parentWidth .lgDownFullWidthScrollableX {
        overflow-x: auto;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .parentWidth .lgDownRemoveSticky {
        position: relative !important;
    }
}

@container parent (max-width: 900px) {
    .parentWidth .mdDownPositionStatic {
        position: static !important;
    }

    .parentWidth .mdDownDisplayNone {
        display: none !important;
    }

    .parentWidth .mdDownFlexUnset {
        flex: unset !important;
    }

    .parentWidth .mdDownPadding0 {
        padding: 0 !important;
    }

    .parentWidth .mdDownWrap {
        flex-wrap: wrap !important;
    }

    .parentWidth .mdDownWrapReverse {
        flex-wrap: wrap-reverse !important;
    }

    .parentWidth .mdDownJustifySpaceBetween {
        justify-content: space-between !important;
    }

    .parentWidth .mdDownAlignStart {
        align-items: flex-start !important;
    }

    .parentWidth .mdDownAlignCenter {
        align-items: center !important;
    }

    .parentWidth .mdDownJustifyCenter {
        justify-content: center !important;
    }

    .parentWidth .mdDownRow {
        flex-direction: row !important;
    }

    .parentWidth .mdDownRowReverse {
        flex-direction: row-reverse !important;
    }

    .parentWidth .mdDownColumn {
        flex-direction: column !important;
    }

    .parentWidth .mdDownColumnReverse {
        flex-direction: column-reverse !important;
    }

    .parentWidth .mdDownTextAlignLeft {
        text-align: left !important;
    }

    .parentWidth .mdDownTextAlignCenter {
        text-align: center !important;
    }

    .parentWidth .mdDownWidth100 {
        width: 100% !important;
    }

    .parentWidth .mdDownMaxWidthUnset {
        max-width: unset !important;
    }

    .parentWidth .mdDownHeight100 {
        height: 100% !important;
    }

    .parentWidth .mdDownHeightAuto {
        height: auto !important;
    }

    .parentWidth .mdDownMarginAuto {
        margin: auto !important;
    }

    .parentWidth .mdDownScrollX {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .parentWidth .mdDownScrollY {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .parentWidth .mdDownFullWidthScrollableX {
        overflow-x: auto;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .parentWidth .mdDownRemoveSticky {
        position: relative !important;
    }
}

@container parent (max-width: 600px) {
    .parentWidth .smDownPositionStatic {
        position: static !important;
    }

    .parentWidth .smDownDisplayNone {
        display: none !important;
    }

    .parentWidth .smDownFlexUnset {
        flex: unset !important;
    }

    .parentWidth .smDownPadding0 {
        padding: 0 !important;
    }

    .parentWidth .smDownWrap {
        flex-wrap: wrap !important;
    }

    .parentWidth .smDownWrapReverse {
        flex-wrap: wrap-reverse !important;
    }

    .parentWidth .smDownJustifySpaceBetween {
        justify-content: space-between !important;
    }

    .parentWidth .smDownJustifyCenter {
        justify-content: center !important;
    }

    .parentWidth .smDownAlignStart {
        align-items: flex-start !important;
    }

    .parentWidth .smDownAlignCenter {
        align-items: center !important;
    }

    .parentWidth .smDownRow {
        flex-direction: row !important;
    }

    .parentWidth .smDownRowReverse {
        flex-direction: row-reverse !important;
    }

    .parentWidth .smDownColumn {
        flex-direction: column !important;
    }

    .parentWidth .smDownColumnReverse {
        flex-direction: column-reverse !important;
    }

    .parentWidth .smDownTextAlignLeft {
        text-align: left !important;
    }

    .parentWidth .smDownTextAlignCenter {
        text-align: center !important;
    }

    .parentWidth .smDownWidth100 {
        width: 100% !important;
    }

    .parentWidth .smDownMaxWidthUnset {
        max-width: unset !important;
    }

    .parentWidth .smDownHeight100 {
        height: 100% !important;
    }

    .parentWidth .smDownHeightAuto {
        height: auto !important;
    }

    .parentWidth .smDownMarginAuto {
        margin: auto !important;
    }

    .parentWidth .smDownScrollX {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .parentWidth .smDownScrollY {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .parentWidth .smDownFullWidthScrollableX {
        overflow-x: auto;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        padding-right: 0;
    }
}
